import { UpdateSyncEntityGroupExternalMappingResponse } from '../../../@bridge/home-engine/addon/models/services/entity-mapping/update-sync-entity-group-external-mapping-response';
import { UpdateSyncEntityGroupExternalMappingRequest } from '../../../@bridge/home-engine/addon/models/services/entity-mapping/update-sync-entity-group-external-mapping-request';
import { EntityMapping } from '../../../@bridge/home-engine/addon/models/services/entity-mapping/entity-mapping';
import { VendorEntity } from '../../../@bridge/home-engine/addon/models/services/vendor-entity/vendor-entity';
/* @ts-ignore TSLint doesn't identify, but still uses the following module. */
import GetIntegration from '@bridge/home-engine/addon/models/services/data/get-integration';
import { GetSyncSchedulesResponse } from '@bridge/home-engine/addon/models/services/data/get-sync-schedules-response';
import { ListCategoryAggregationMappingsResponse } from '@bridge/home-engine/addon/models/services/category-aggregations/list-category-aggregation-mappings-response';
import IntegrationResponse from '@bridge/home-engine/addon/models/services/data/integration-response';
import { PostIntegrationSettingsRequest } from '@bridge/home-engine/addon/models/services/data/post-integration-settings';
import { PostLocationSettingsRequest } from '@bridge/home-engine/addon/models/services/data/post-location-settings';
import PostSource from '@bridge/home-engine/addon/models/services/data/post-source';
import { PostTaxSettingsRequest } from '@bridge/home-engine/addon/models/services/data/post-tax-settings';
import { SaveSyncSchedulesRequest } from '@bridge/home-engine/addon/models/services/data/save-sync-schedules-request';
import { SaveSyncSchedulesResponse } from '@bridge/home-engine/addon/models/services/data/save-sync-schedules-response';
import SourceResponse from '@bridge/home-engine/addon/models/services/data/source-response';
import { SquareLocationResponse } from '@bridge/home-engine/addon/models/services/data/square-location-response';
import { SquareLocationsResponse } from '@bridge/home-engine/addon/models/services/data/square-locations-response';
import { SyncJobType as SyncType } from '@bridge/home-engine/addon/models/services/sync-type-status/sync-job-type';
import Location from '@bridge/home-engine/addon/models/services/location/location';
import BridgeSquareLocation from '@bridge/home-engine/addon/models/services/location/square_location';
import { CreateMerchantApplicationResponse } from '@bridge/home-engine/addon/models/services/merchant-application/create-merchant-application-response';
import { GetMerchantApplicationsResponse } from '@bridge/home-engine/addon/models/services/merchant-application/get-merchant-applications-response';
import { MerchantApplication } from '@bridge/home-engine/addon/models/services/merchant-application/merchant-application';
import { ResetMerchantTokenResponse } from '@bridge/home-engine/addon/models/services/merchant-application/reset-merchant-token-response';
import { GetPluginApplications } from '@bridge/home-engine/addon/models/services/plugin-application/get-plugin-applications';
import { PluginApplication } from '@bridge/home-engine/addon/models/services/plugin-application/plugin-application';
import { SyncSchedule } from '@bridge/home-engine/addon/models/services/sync-schedule/sync-schedule';
import Service, { inject as service } from '@ember/service';
import Auth from 'bridge-dashboard/app/services/auth';
import CurrentUser from 'bridge-dashboard/app/services/current-user';
import FetchHandler from 'bridge-dashboard/app/services/fetch-handler';
import generateQueryParameters, {
  QueryParams,
} from '../utils/query-parameter-generator';
// @ts-ignore module exists in ember namespace but ts wants literal path
import { EngineRouterService } from 'ember-engines-router-service';

import Integration from '../../../@bridge/home-engine/addon/models/services/integration/integration';
import GetLocations from '../../../@bridge/home-engine/addon/models/services/location/get-locations';
import GetSources from '../../../@bridge/home-engine/addon/models/services/source/get-sources';
import {
  Gateway,
  Source,
} from '../../../@bridge/home-engine/addon/models/services/source/source';
import {
  GetSyncEntityGroupStatusResponse,
  GetSyncEntityGroupStatusesResponse,
} from '../../../@bridge/home-engine/addon/models/services/sync-entity-group-status/get-sync-entity-group-statuses';
import SyncEntityGroupStatus from '../../../@bridge/home-engine/addon/models/services/sync-entity-group-status/sync-entity-group-status';
import ListSyncTypeStatusesResponse from '../../../@bridge/home-engine/addon/models/services/sync-type-status/get-sync-type-statuses';
import { SyncTypeStatus } from '../../../@bridge/home-engine/addon/models/services/sync-type-status/sync-type-status';
import enrichEntityWithSquareUrl from '../utils/enrich-entity-with-square-url';
import { CategoryAggregationMapping } from '@bridge/home-engine/addon/models/services/category-aggregations/category-aggregation-mapping';
import { CategoryAggregationRow } from '@bridge/home-engine/addon/models/services/category-aggregations/category-aggregation-row';
import { UpsertCategoryAggregationMappingsRequest } from '@bridge/home-engine/addon/models/services/category-aggregations/upsert-category-aggregation-mappings-request';
import { UpsertCategoryAggregationMappingsResponse } from '@bridge/home-engine/addon/models/services/category-aggregations/upsert-category-aggregation-mappings-response';

interface GetSyncEntityGroupStatusQueryParams extends QueryParams {
  cursor: string;
  entity_id: string;
  state: string;
  sync_type: string;
  entity_type: string;
}

interface GetAccountSourcesQueryParams extends QueryParams {
  gateway: Gateway;
}

interface LocationsService {
  getLocationsBySyncType(syncJobType: SyncType): Promise<Location[]>;
}

export {
  GetSyncEntityGroupStatusQueryParams,
  GetAccountSourcesQueryParams,
  LocationsService,
};

export default class Data extends Service implements LocationsService {
  @service auth!: Auth;
  @service currentUser!: CurrentUser;
  @service fetchHandler!: FetchHandler;
  // @ts-ignore no types for Flash service
  @service flash;
  @service router!: EngineRouterService;

  resourcePrefix = '/api/v1';

  get currentIntegrationId(): string {
    return this.currentUser!.currentIntegration!.id!;
  }

  get currentAccountId(): string {
    return this.currentUser!.currentAccount!.id!;
  }

  getIntegration(
    integrationId: string = this.currentIntegrationId!
  ): Promise<Integration> {
    return this.fetchHandler
      .doGetAndHandleResponse<GetIntegration>(
        `${this.resourcePrefix}/accounts/${this.currentAccountId}/integrations/${integrationId}`
      )
      .then((json) => json.integration);
  }

  postIntegration(createIntegrationRequestBody: {
    integration: Integration;
  }): Promise<Integration> {
    return this.fetchHandler
      .doPostAndHandleResponse<IntegrationResponse>(
        `${this.resourcePrefix}/integrations`,
        createIntegrationRequestBody
      )
      .then((json) => json.integration);
  }

  postIntegrationSettings(
    writeIntegrationSettingsBody: PostIntegrationSettingsRequest
  ): Promise<Integration> {
    return this.fetchHandler
      .doPostAndHandleResponse<IntegrationResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/settings/integration`,
        writeIntegrationSettingsBody
      )
      .then((json) => json.integration);
  }

  postLocationSettings(
    squareLocationId: string,
    writeLocationSettingsBody: PostLocationSettingsRequest
  ): Promise<Integration> {
    return this.fetchHandler
      .doPostAndHandleResponse<IntegrationResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/settings/locations/${squareLocationId}`,
        writeLocationSettingsBody
      )
      .then((json) => json.integration);
  }

  postTaxSettings(
    writeTaxSettingsBody: PostTaxSettingsRequest
  ): Promise<Integration> {
    return this.fetchHandler
      .doPostAndHandleResponse<IntegrationResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/settings/tax`,
        writeTaxSettingsBody
      )
      .then((json) => json.integration);
  }

  postSourceSettings(
    writeSourceSettingsBody: Record<string, any>,
    sourceId: string
  ): Promise<Source> {
    return this.fetchHandler
      .doPostAndHandleResponse<SourceResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/settings/sources/${sourceId}`,
        writeSourceSettingsBody
      )
      .then(
        (json) => json.source,
        (errorString) => {
          throw errorString;
        }
      );
  }

  getAccountSources(
    params?: GetAccountSourcesQueryParams
  ): Promise<Array<Source>> {
    let requestUrl = `${this.resourcePrefix}/accounts/${this.currentAccountId}/sources`;
    if (params) {
      requestUrl += generateQueryParameters(params);
    }
    return this.fetchHandler
      .doGetAndHandleResponse<GetSources>(requestUrl)
      .then((json) => json.sources);
  }

  getSource(sourceId: string): Promise<Source> {
    return this.fetchHandler
      .doGetAndHandleResponse<SourceResponse>(
        `${this.resourcePrefix}/accounts/${this.currentAccountId}/sources/${sourceId}`
      )
      .then((json) => json.source);
  }

  postSource(createSourceRequestBody: PostSource): Promise<Source> {
    return this.fetchHandler
      .doPostAndHandleResponse<SourceResponse>(
        `${this.resourcePrefix}/accounts/${this.currentAccountId}/sources`,
        createSourceRequestBody
      )
      .then((json) => json.source);
  }

  getLocationsBySyncType(syncType: string): Promise<Location[]> {
    return this.fetchHandler
      .doGetAndHandleResponse<GetLocations>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/locations?sync_type=${syncType}`
      )
      .then((json) => json.locations);
  }

  getLocations(): Promise<Array<Location>> {
    return this.fetchHandler
      .doGetAndHandleResponse<GetLocations>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/locations`
      )
      .then((json) => json.locations);
  }

  getSquareLocationById(
    squareLocationId: string
  ): Promise<BridgeSquareLocation> {
    return this.fetchHandler
      .doGetAndHandleResponse<SquareLocationResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/square-locations/${squareLocationId}`
      )
      .then((json) => json.square_location);
  }

  syncLocations(): Promise<Array<BridgeSquareLocation>> {
    return this.fetchHandler
      .doPostAndHandleResponse<SquareLocationsResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/sync-locations`
      )
      .then(
        (json) => json.square_locations
      )
  }

  getSyncEntityGroupStatus(pairId: string): Promise<SyncEntityGroupStatus> {
    return this.fetchHandler
      .doGetAndHandleResponse<GetSyncEntityGroupStatusResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/sync-entity-group-statuses/${pairId}`
      )
      .then((json) => enrichEntityWithSquareUrl(json.sync_entity_group_status));
  }

  getSyncEntityGroupStatuses(
    params: GetSyncEntityGroupStatusQueryParams
  ): Promise<GetSyncEntityGroupStatusesResponse> {
    const paramsString = generateQueryParameters(params);
    return (
      this.fetchHandler
        .doGetAndHandleResponse<GetSyncEntityGroupStatusesResponse>(
          `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/sync-entity-group-statuses${paramsString}`
        )
        // response body contains sync entity group status and cursor
        .then((json) => ({
          ...json,
          sync_entity_group_statuses: json.sync_entity_group_statuses.map(
            (pairedEntity) => enrichEntityWithSquareUrl(pairedEntity)
          ),
        }))
    );
  }

  getSyncTypeStatuses(
    integrationId: string = this.currentIntegrationId!
  ): Promise<Array<SyncTypeStatus>> {
    return this.fetchHandler
      .doGetAndHandleResponse<ListSyncTypeStatusesResponse>(
        `${this.resourcePrefix}/integrations/${integrationId}/sync-type-statuses`
      )
      .then((json) => json.sync_type_statuses);
  }

  getMerchantApplicationsResponse(
    bridgeAccountId: string = this.currentAccountId
  ): Promise<Array<MerchantApplication>> {
    return this.fetchHandler
      .doGetAndHandleResponse<GetMerchantApplicationsResponse>(
        `${this.resourcePrefix}/accounts/${bridgeAccountId}/merchant-applications`
      )
      .then((json) => json.merchant_applications);
  }

  getPluginApplications(): Promise<Array<PluginApplication>> {
    return this.fetchHandler
      .doGetAndHandleResponse<GetPluginApplications>(
        `${this.resourcePrefix}/plugin-applications`
      )
      .then((json) => json.plugin_applications);
  }

  createMerchantApplication(
    merchantId: string,
    applicationId: string
  ): Promise<CreateMerchantApplicationResponse> {
    return this.fetchHandler
      .doPostAndHandleResponse<CreateMerchantApplicationResponse>(
        `${this.resourcePrefix}/merchant-applications`,
        { merchant_id: merchantId, application_id: applicationId }
      )
      .then((json) => json);
  }

  resetMerchantToken(merchantApplicationId: number): Promise<string> {
    return this.fetchHandler
      .doPostAndHandleResponse<ResetMerchantTokenResponse>(
        `${this.resourcePrefix}/merchant-applications/reset-token`,
        { merchant_application_id: merchantApplicationId }
      )
      .then((json) => json.merchant_token);
  }

  getSyncSchedules(): Promise<Array<SyncSchedule>> {
    return this.fetchHandler
      .doGetAndHandleResponse<GetSyncSchedulesResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/sync-schedules`
      )
      .then((json) => json.sync_schedules);
  }

  saveSyncSchedules(
    request: SaveSyncSchedulesRequest
  ): Promise<Array<SyncSchedule>> {
    return this.fetchHandler
      .doPostAndHandleResponse<SaveSyncSchedulesResponse>(
        `${this.resourcePrefix}/integrations/${this.currentIntegrationId}/sync-schedules`,
        request
      )
      .then((json) => json.sync_schedules);
  }

  updateExternalMapping(
    integrationId: string,
    request: UpdateSyncEntityGroupExternalMappingRequest
  ): Promise<EntityMapping> {
    return this.fetchHandler
      .doPutAndHandleResponse<UpdateSyncEntityGroupExternalMappingResponse>(
        `${this.resourcePrefix}/integrations/${integrationId}/sync-entity-group-statuses/entity-mapping`,
        request
      )
      .then((json) => json.mapping);
  }

  getVendorEntity(
    integrationSourceId: string,
    externalId: string,
    entityType: string
  ): Promise<VendorEntity> {
    return this.fetchHandler
      .doGetAndHandleResponse<VendorEntity>(
        `${this.resourcePrefix}/integration-source/${integrationSourceId}/type/${entityType}/entity/${externalId}`
      )
      .then((json) => json);
  }

  getCategoryAggregationMappings(
    locationId: string
  ): Promise<CategoryAggregationRow[]> {
    return this.fetchHandler
      .doGetAndHandleResponse<ListCategoryAggregationMappingsResponse>(
        `/integrations/${this.currentIntegrationId}/locations/${locationId}/category-aggregation-mappings`
      )
      .then((json) => json.categoryAggregationRows);
  }

  upsertCategoryAggregationMappings(
    locationId: string,
    request: UpsertCategoryAggregationMappingsRequest
  ): Promise<CategoryAggregationMapping[]> {
    return this.fetchHandler
      .doPostAndHandleResponse<UpsertCategoryAggregationMappingsResponse>(
        `/integrations/${this.currentIntegrationId}/locations/${locationId}/category-aggregation-mappings`,
        request
      )
      .then(
        (json: UpsertCategoryAggregationMappingsResponse) =>
          json.orders_sync_configuration.category_aggregation_mappings
      );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    data: Data;
  }
}
