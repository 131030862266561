import { Source } from '@bridge/home-engine/addon/models/services/source/source';

export default function getNameFromIntegrationSource(extIntegrationSource: Source): string {
  if(!extIntegrationSource) {
    return "Invalid Integration Source";
  } else if(extIntegrationSource.cloud_elements_instance) {
    return extIntegrationSource.cloud_elements_instance.name;
  } else if(extIntegrationSource.external_source) {
    const external_source = extIntegrationSource.external_source;
    return `${external_source.gateway_system_type} (${external_source.system_id})`;
  } else {
    return "Unnamed Integration Source";
  }
}
