import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class NotFound extends Controller {
  
  @action
  goBack () {
    history.back();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'not-found': NotFound;
  }
}
