import Application from '@ember/application';
import { defineCustomElements } from '@market/web-components/loader';
import * as Sentry from '@sentry/ember';
import config from 'bridge-dashboard/config/environment';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

Sentry.init({
  dsn: 'https://94b316a689224fb48ec8620a5cb78903@o160250.ingest.sentry.io/5531007',
  environment: config.TARGET,
  tracesSampleRate: 1.0,
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;

  /**
   * NOTE: engine names must be in camel-case, but, given a nested engine,
   * still include the route name punctation.
   *
   * All routes and services used by engine addons must be declared here
   * AND in the engine addons' engine.js file.
   */
  engines = {
    '@bridge/home-engine': {
      dependencies: {
        externalRoutes: { login: 'login' },
        services: [
          'auth',
          'current-user',
          'data',
          'fetch-handler',
          'flash',
          'navigation',
          'popover-manager',
        ],
      },
    },
  };
}

defineCustomElements();

loadInitializers(App, config.modulePrefix);
