import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import CurrentUser from '../services/current-user';
import FlashService from '@square/glass-ui/addon/services/flash';

export default class Login extends Route {
  @service currentUser!: CurrentUser;
  @service flash!: FlashService;

  model(params: { error_message: string; }) {
    return this.currentUser.loadUser()
      .then(() => this.transitionTo('@bridge/home-engine'))
      .catch(() => {
        this.handleErrorParameter(params.error_message);
      });
  }

  handleErrorParameter(encodedErrorMessage: string) {
    if (encodedErrorMessage) {
      try {
        const errorMessage = atob(encodedErrorMessage);
        this.flash.globalError(
          // TODO: Base64 decode message
          
          `Failed to sign in: ${errorMessage}`,
          {
            componentClass: 'flash-banner__login',
            dismiss: () => {
              this.flash.clearGlobalMessage();
            },
          }
        );
      } catch (error) {
        // Do nothing if invalid error message
      }
    }
  }
}
