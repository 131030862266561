import Component from '@glimmer/component';
import { MarketPillVariant } from '@market/web-components/dist/types/utils/pill-variant';
import config from 'bridge-dashboard/config/environment';

interface PillProperties {
  text: string;
  variant: MarketPillVariant;
}

interface EnvIndicatorProperties {
  env: string;
}

export default class EnvIndicator extends Component<EnvIndicatorProperties> {
  environmentName: string;
  variant: MarketPillVariant;
  visible: boolean;
  constructor(owner: unknown, args: EnvIndicatorProperties) {
    super(owner, args);
    const pillProps = this.getPillProperties(args.env || config.TARGET);
    this.environmentName = pillProps.text;
    this.variant = pillProps.variant;
    this.visible = this.environmentName !== ``;
  }

  private getPillProperties(env?: string): PillProperties {
    switch (env?.toLowerCase()) {
      // we aren't displaying for production,
      // maybe we can for impersonation later
      case 'sndbx':
        return {
          text: 'Sandbox',
          variant: 'warning',
        };
      case 'dev':
        return {
          text: 'Development',
          variant: 'critical',
        };
      case 'local':
        return {
          text: 'Local',
          variant: 'alpha',
        };
      default:
        return {
          text: ``,
          variant: 'normal',
        };
    }
  }
}
