export interface QueryParams {
  [key: string]: string;
}

export default function generateQueryParameters(params: QueryParams): string {
  let paramsString: string = '';
  let paramStrings: Array<string> = [];

  const paramSet = Array.from(new Set(Object.values(params)));

  if (paramSet.length === 1 && paramSet[0] === undefined) {
    return paramsString;
  } else {
    paramStrings = Object.keys(params)
      .map((key) => {
        const paramValue = params[key];
        if (paramValue != undefined) {
          return `${key}=${paramValue}`;
        } else {
          return '';
        }
      })
      .filter((string) => string !== '');
  }

  if (paramStrings.length > 0) {
    paramsString = '?' + paramStrings.join('&');
  }

  return paramsString;
}
