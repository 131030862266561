import SyncEntityGroupStatus from '../../../@bridge/home-engine/addon/models/services/sync-entity-group-status/sync-entity-group-status';
import ENV from '../config/environment';

export default function enrichEntityWithSquareUrl(
  pairedEntity: SyncEntityGroupStatus
): SyncEntityGroupStatus {
  switch (pairedEntity.entity_type) {
    case 'CATALOG_CATEGORY':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/items/categories`,
      };
    case 'CATALOG_TAX':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/business/manual-taxes/${pairedEntity.square_entity_id}`,
      };
    case 'CATALOG_ITEM':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/items/library/${pairedEntity.square_entity_id}`,
      };
    case 'CATALOG_DISCOUNT':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/items/discounts/${pairedEntity.square_entity_id}`,
      };
    case 'MODIFIER':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/items/modifiers/${pairedEntity.square_entity_id}`,
      };
    case 'CUSTOMER':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/customers/directory/customer/${pairedEntity.square_entity_id}`,
      };
    case 'ORDER':
    case 'CREDIT_MEMO':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/sales/transactions/${pairedEntity.square_entity_id}`,
      };
    case 'BANK_TRANSFER': {
      return {
        ...pairedEntity,
        // eslint-disable-next-line max-len
        square_url: `${ENV.squareDashboardUrl}/dashboard/square-card/activity/transfers/${pairedEntity.square_entity_id}/by-unit/${pairedEntity.square_location_id}`,
      };
    }
    // Todo: Figure out if the following enity types have urls associated with them
    // case 'CATALOG_ITEM_VARIATION':
    // case 'PAYMENT':
    // case 'REFUND':
    case 'INVENTORY_ADJUSTMENT_UPWARD':
    case 'INVENTORY_ADJUSTMENT_DOWNWARD':
    case 'INVENTORY_PHYSICAL_COUNT':
    case 'INVENTORY_TRANSFER':
    case 'INVENTORY_LEVEL':
      return {
        ...pairedEntity,
        square_url: `${ENV.squareDashboardUrl}/dashboard/items/inventory/history`,
      };
    default:
      return pairedEntity;
  }
}