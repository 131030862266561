import Component from '@ember/component';
import { focusableClass } from '../services/popover-manager';
import { inject } from '@ember/service';
import { computed } from '@ember/object';

interface PopoverManager {
  close: (elementId: string) => void
  open: (elementId: string) => void
}

// this component constitutes the dropdown/modal nature of the account menu
export default Component.extend({
  classNames: ['popover-menu'],
  
  /* @ts-ignore triggered by old Glass/Dashboard Ember code */
  popoverManager: inject('popover-manager') as PopoverManager,

  isOpen: computed('popoverManager.currentPopoverID', 'elementId', function() {
    return this.popoverManager.currentPopoverID === this.elementId;
  }),

  actions: {
    toggle() {
      const elementId = this.get('elementId');
      
      if (this.isOpen!) {
        this.popoverManager.close(elementId);
      } else {
        this.popoverManager.open(elementId);
      }
    },

    setFocus(firstOrLast: 'first' | 'last') {
      const focusableElements = this.element.querySelectorAll(
        `.${focusableClass}:not([disabled])`
      ) as NodeListOf<HTMLElement>

      firstOrLast === 'first'
        ? focusableElements[0].focus()
        : focusableElements[focusableElements.length - 1].focus();
    },
  },
});
