import Service, { inject as service } from '@ember/service';
import ENV from '../config/environment';
import Ember from 'ember';

import BridgeApiException from '../types/bridge-api-exception';
import { BridgeErrorResponse } from '../types/bridge-error-response';

import Auth from './auth';

const CSRF_TOKEN_COOKIE_NAME = 'XSRF-TOKEN';
const CSRF_TOKEN_HEADER_NAME = 'X-XSRF-TOKEN';
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export default class FetchHandler extends Service {
  @service auth!: Auth;
  @service flash!: any;
  @service router!: any;

  doGetAndHandleResponse<R>(url: string): Promise<R> {
    return this.handleResponse<R>(this.doGet(url));
  }

  doPostAndHandleResponse<R>(url: string, body?: object): Promise<R> {
    return this.handleResponse<R>(this.doPost(url, body));
  }

  doPutAndHandleResponse<R>(url: string, body?: object): Promise<R> {
    return this.handleResponse<R>(this.doPut(url, body));
  }

  private doGet(url: string): Promise<Response> {
    return fetch(ENV.frontEndServiceUrl + url, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache',
      headers: DEFAULT_HEADERS,
    });
  }

  private doPost(url: string, body?: object): Promise<Response> {
    const csrfToken = this.parseCSRFTokenCookieValue();
    const headers: Record<string, string> = { ...DEFAULT_HEADERS };
    headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;

    return fetch(ENV.frontEndServiceUrl + url, {
      method: 'POST',
      credentials: 'include',
      cache: 'no-cache',
      headers,
      body: JSON.stringify(body),
    });
  }

  private doPut(url: string, body?: object): Promise<Response> {
    const csrfToken = this.parseCSRFTokenCookieValue();
    const headers: Record<string, string> = { ...DEFAULT_HEADERS };
    headers[CSRF_TOKEN_HEADER_NAME] = csrfToken;

    return fetch(ENV.frontEndServiceUrl + url, {
      method: 'PUT',
      credentials: 'include',
      cache: 'no-cache',
      headers,
      body: JSON.stringify(body),
    });
  }

  private handleResponse<R>(promise: Promise<Response>): Promise<R> {
    return promise
      .then((response) => {
        if (response.ok) {
          // When the user logs out, we receive a 204 with an empty body, therfore we're not able to call .json()
          if (response.status === 204) return response;
          return response.json().then((json) => json);
        } else {
          return response.json().then((json: BridgeErrorResponse) => {
            const message = json.error.presentable_error || json.error.detail;
            // Gets "passed on" to .catch
            throw new BridgeApiException(message, response);
          });
        }
      })
      .catch((error: BridgeApiException) => {
        if (error?.response) {
          if (error.response.status !== 401) {
            this.flash.globalError(error.message, {
              dismiss: () => {
                this.flash.clearGlobalMessage();
              },
            });
          } else {
            if (window.location.pathname !== '/login') {
              window.location.pathname = '/login';
            }
          }
        }
        throw error;
      });
  }

  private parseCSRFTokenCookieValue(): string {
    const allCookies = document.cookie;
    const csrfTokenCookie = allCookies
      .split('; ')
      .find((cookieString) => cookieString.includes(CSRF_TOKEN_COOKIE_NAME));
    if (csrfTokenCookie) {
      const csrfTokenCookieValue = Ember.testing
        ? 'ABC-123-CSRF-TEST'
        : csrfTokenCookie.split('=')[1];
      return csrfTokenCookieValue;
    }
    return '';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'fetch-handler': FetchHandler;
  }
}
