import Component from '@ember/component';
import { computed } from '@ember/object';
import { gt } from '@ember/object/computed';

const COLOR_VARIANT_DEFAULT = 'default';
const COLOR_VARIANT_INVERSE = 'inverse';

export default Component.extend({
  tagName: '',

  /**
   * Passed in. The number of notifications there are, so the icon can reflect that.
   */
  count: 0,

  /**
   * Passed in
   *
   * Valid variants are 'default' (used on a light background) and 'inverse' (used on a dark background)
   * null, undefined, or any other invalid values will be silently treated as 'default'
   */
  colorVariant: COLOR_VARIANT_DEFAULT,

  hasNotifications: gt('count', 0),

  hasNotificationsClass: computed('hasNotifications', function() {
    if (this.hasNotifications) {
      return 'notifications-icon--has-notifications';
    } else {
      return '';
    }
  }),

  colorVariantClass: computed('colorVariant', function() {
    if (this.colorVariant === COLOR_VARIANT_INVERSE) {
      return 'notifications-icon--inverse';
    } else {
      return '';
    }
  }),

  svgClass: computed('colorVariantClass', 'hasNotificationsClass', function() {
    const colorVariantClass = this.colorVariantClass;
    const hasNotificationsClass = this.hasNotificationsClass;
    return `notifications-icon ${colorVariantClass} ${hasNotificationsClass}`;
  }),
});
