import FlashService from '@square/glass-ui/addon/services/flash';
import Service, {inject as service} from '@ember/service';
import Router from '@ember/routing/router';
import CurrentUser from './current-user';
import ENV from '../config/environment';

import FetchHandler from './fetch-handler';
import ModalService from '@square/glass-ui/addon/services/modal';


/**
 * Service responsible for managing the authentication state of the current user.
 * User auth state source of truth = Secure HttpOnly JWT cookie issued by Bridge FES
 * Works with CurrentUser service to facilitate logging in/out.
 * Tracks time remaining before JWT cookie expires.
 */
export default class Auth extends Service {
  @service currentUser!: CurrentUser;
  @service fetchHandler!: FetchHandler;
  @service router!: Router;
  // no type for Glass modal or flash service as library doesn't come with types
  @service flash!: FlashService;
  @service modal!: ModalService;

  googleOidcAuthenticationLink: string = `${ENV.frontEndServiceUrl}/api/v1/oauth2/authorize/google?redirect_uri=${ENV.frontEndClientUrl}/oauth2/redirect`;
  microsoftOidcAuthenticationLink: string = `${ENV.frontEndServiceUrl}/api/v1/oauth2/authorize/azure?redirect_uri=${ENV.frontEndClientUrl}/oauth2/redirect`;
  squareOidcAuthenticationLink: string = `${ENV.frontEndServiceUrl}/api/v1/oauth2/authorize/square?redirect_uri=${ENV.frontEndClientUrl}/oauth2/redirect`

  async refreshJwt(): Promise<void> {
    await this.fetchHandler.doGetAndHandleResponse('/users/me/refresh');
  }

  logout(): void {
    this.fetchHandler.doGetAndHandleResponse('/logout')
      .then(
        () => {
          this.currentUser.clear();
          this.flash.clearGlobalMessage();
          this.router.transitionTo('login');
        },
        () => {
          window.history.back();
          this.flash.globalError('Failed to log out.', {persistent: false});
        }
      );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    auth: Auth;
  }
}
