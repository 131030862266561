import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import {
  ActivePageObject,
  NavigationItemObject,
  NavigationItemRouteObject,
} from '../types/navigation';

import CurrentUser from './current-user';

export interface NavigationServiceObject {
  activePage: ActivePageObject;
  currentPageLabel: Function;
  navigationItemOverview: NavigationItemObject;
  navigationItemSettings: NavigationItemObject;
  navigationItemAuditLog: NavigationItemObject;
  navigationItemPairingStatuses: NavigationItemObject;
  setNavigationItemsWithRouteInfo(): void;
  navigationItemsWithRouteInfo: Array<NavigationItemObject>;
}

/**
 * Service used to populate navigation sidebar.
 * Made and named in the image of a Dashboard Ember service.
 */
export default class Navigation extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  @service currentUser!: CurrentUser;
  @service router!: RouterService;

  @tracked
  navigationItemsWithRouteInfo: Array<NavigationItemObject> = this.getNavigationItemsWithRouteInfo();

  // returns the label of the current page by iterating over the all navigation objects
  // and finding the one that matches the current route
  currentPageLabel = (): string => {
    let currentPageLabel = '';
    this.navigationItemsWithRouteInfo.forEach(
      (navItem: NavigationItemObject) => {
        if (navItem.children.length > 0) {
          navItem.children.forEach((navItemChild: NavigationItemObject) => {
            if (this.isHere(navItemChild.route)) {
              currentPageLabel = navItemChild.item.label;
            }
          });
        } else {
          if (this.isHere(navItem.route)) {
            currentPageLabel = navItem.item.label;
          }
        }
      }
    );
    if (currentPageLabel !== '') {
      return currentPageLabel;
    } else {
      return '';
    }
  };

  // determines if the current route is the same as the navigation object route
  // by comparing the Ember route name (e.g. integration.overview) and (if) any
  // query parameters
  isHere = (navLinkRoute: NavigationItemRouteObject): boolean => {
    let queryParamsMatch = true;
    Object.keys(navLinkRoute.queryParams).forEach((key) => {
      if (
        this.router.currentRoute &&
        this.router.currentRoute.queryParams[key] &&
        navLinkRoute.queryParams[key] &&
        navLinkRoute.queryParams[key].toLowerCase() ===
          this.router.currentRoute.queryParams[key]!.toLowerCase()
      ) {
        queryParamsMatch = true;
      } else {
        queryParamsMatch = false;
      }
    });
    return (
      this.router.currentRouteName.includes(navLinkRoute.name) &&
      queryParamsMatch
    );
  };

  // normal class body definition here
  // TODO: map route to activePage string
  activePage: ActivePageObject = {
    label: 'Bridge',
  };

  /* these objects are stubs; the sidenav of Square apps originations from a YAML
  file digested and served by a back-end service. If/when this app integrates
  with dashboard, we'll need to update this Ember service accordingly */
  navigationItemOverview: NavigationItemObject = {
    item: {
      app_id: 'bridge',
      description: 'Overview of syncs.',
      id: 'overview',
      label: 'Overview',
      link: '/home/overview',
      mobilized: true,
    },
    route: {
      name: 'authorized-route.integration.overview',
      queryParams: {},
    },
    children: [],
  };

  navigationItemSettings: NavigationItemObject = {
    item: {
      app_id: 'bridge',
      description: 'Integration settings.',
      id: 'settings',
      label: 'Settings',
      link: '/home/settings',
      mobilized: true,
    },
    children: [
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Settings that affect the entire integration.',
          id: 'settings_integration',
          label: 'Integration Settings',
          link: '/home/settings/integration',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.integration',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Tax settings that affect the entire integration.',
          id: 'settings_tax',
          label: 'Tax Settings',
          link: '/home/settings/tax',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.tax',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Location-specific integration settings.',
          id: 'settings_location',
          label: 'Location Settings',
          link: '/home/settings/location',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.locations',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Order category aggregation settings.',
          id: 'settings_category_aggregations',
          label: 'Category Aggregations',
          link: '/home/settings/category-aggregations',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.category-aggregations',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Integration source settings.',
          id: 'settings_source',
          label: 'Source Settings',
          link: '/home/settings/source',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.source',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Integration sync schedule settings.',
          id: 'settings_sync_schedules',
          label: 'Sync Schedules',
          link: '/home/settings/sync-schedules',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.sync-schedules',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Measurement units settings.',
          id: 'settings_measurement_unit_mappings',
          label: 'Measurement Unit Mappings',
          link: '/home/settings/measurement-units',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.measurement-unit-mappings',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Integration item modifier mappings settings.',
          id: 'settings_item_modifier_mappings',
          label: 'Item Modifier Mappings',
          link: '/home/settings/item-modifier-mappings',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.item-modifier-mappings',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Integration discount mappings.',
          id: 'settings_discount_mappings',
          label: 'Discount Mappings',
          link: '/home/settings/discount-mappings',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.settings.discount-mappings',
          queryParams: {},
        },
        useLinkTo: true,
      }
    ],
    route: {
      name: 'authorized-route.integration.settings',
      queryParams: {},
    },
  };

  navigationItemAuditLog: NavigationItemObject = {
    item: {
      app_id: 'bridge',
      description: 'Audit log of syncs.',
      id: 'audit_log',
      label: 'Audit Log',
      link: '/home/audit',
      mobilized: true,
    },
    route: {
      name: 'authorized-route.integration.audit',
      queryParams: {},
    },
    children: [],
  };

  navigationItemSyncData: NavigationItemObject = {
    item: {
      app_id: 'bridge',
      description: 'Statuses of all attempted entity syncs.',
      id: 'entity_status',
      label: 'Sync Data',
      link: '/home/sync-data',
      mobilized: true,
    },
    children: [
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Statuses of all attempted bank transfer entity syncs.',
          id: 'entity_status_bank_transfers',
          label: 'Bank Transfers',
          link: '/home/sync-data/BANK_TRANSFERS',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.sync-data.bank-transfers',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Statuses of all attempted catalog entity syncs.',
          id: 'entity_status_catalog',
          label: 'Catalog',
          link: '/home/sync-data/catalog',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.sync-data.catalog',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Statuses of all attempted customer entity syncs.',
          id: 'entity_status_customer',
          label: 'Customers',
          link: '/home/sync-data/customers',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.sync-data.customers',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description:
            'Statuses of all attempted inventory levels entity syncs.',
          id: 'entity_status_inventory_levels',
          label: 'Inventory Levels',
          link: '/home/sync-data/inventory-levels',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.sync-data.inventory-levels',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description:
            'Statuses of all attempted inventory changes entity syncs.',
          id: 'entity_status_inventory_changes',
          label: 'Inventory Changes',
          link: '/home/sync-data/inventory-changes',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.sync-data.inventory-changes',
          queryParams: {},
        },
        useLinkTo: true,
      },
      {
        children: [],
        item: {
          app_id: 'bridge',
          description: 'Statuses of all attempted order entity syncs.',
          id: 'entity_status_order',
          label: 'Orders',
          link: '/home/sync-data/orders',
          mobilized: true,
        },
        route: {
          name: 'authorized-route.integration.sync-data.orders',
          queryParams: {},
        },
        useLinkTo: true,
      },
    ],
    route: {
      name: 'authorized-route.integration.sync-data',
      queryParams: {},
    },
  };

  navigationItemPlugins: NavigationItemObject = {
    item: {
      app_id: 'bridge',
      description: 'Plugins.',
      id: 'plugins',
      label: 'Plugins',
      link: '/home/plugins',
      mobilized: true,
    },
    route: {
      name: 'authorized-route.plugins',
      queryParams: {},
    },
    children: [],
  };

  navigationDailyInvoices: NavigationItemObject = {
    item: {
      app_id: 'bridge',
      description: 'Daily invoices.',
      id: 'daily-invoices',
      label: 'Daily Invoices',
      link: '/home/daily-invoices',
      mobilized: true,
    },
    route: {
      name: 'authorized-route.integration.daily-invoices',
      queryParams: {},
    },
    children: [],
  }

  private getActiveNavigationDailyInvoices(): NavigationItemObject | null {
    if (this.hasAggregatedOrders()) {
      return this.navigationDailyInvoices;
    }
    return null;
  }

  private hasAggregatedOrders() {
    if (this.currentUser?.currentIntegration) {
      if (this.currentUser?.currentIntegration.sync_definitions)
        for (let syncDefinition of this.currentUser?.currentIntegration.sync_definitions) {
          if (syncDefinition.sync_type === 'ORDERS'
            && syncDefinition.orders_sync_configuration?.is_aggregated) {
              return true;
            }
        }
    }
    return false;
  }

  private getActiveSyncDataItemChildren(): Array<NavigationItemObject> {
    const activeSyncTypes = this.currentUser.activeSyncTypes;

    return this.navigationItemSyncData.children.filter((child) => {
      const childSyncType: string = child.item.label
        .split(' ')
        .join('_')
        .toUpperCase();

      return activeSyncTypes.find(
        (syncType) => syncType.sync_type === childSyncType
      );
    });
  }

  private getActiveNavigationItemSyncData(): NavigationItemObject | null {
    const activeChildren: Array<NavigationItemObject> = this.getActiveSyncDataItemChildren();
    if (activeChildren.length === 0) {
      return null;
    }
    const activeNavigationItemSyncData: NavigationItemObject = JSON.parse(
      JSON.stringify(this.navigationItemSyncData)
    );
    activeNavigationItemSyncData.children = activeChildren;
    return activeNavigationItemSyncData;
  }

  private getActiveNavigationItemAuditLog(): NavigationItemObject | null {
    const activeChildren: Array<NavigationItemObject> = this.getActiveSyncDataItemChildren();
    if (activeChildren.length === 0) {
      return null;
    }
    return this.navigationItemAuditLog;
  }

  private getActiveNavigationItemSettings(): NavigationItemObject | null {
    const activeChildren: Array<NavigationItemObject> = this.navigationItemSettings.children.filter(
      (child) => {
        // Show Tax Settings only when Advanced Taxes is activated
        if (child.item.id == "settings_tax")
          return this.currentUser.isAdvancedTaxesEnabled()

        // Show Category Aggregations only when an Agg Orders sync definition exists for a location
        if (child.item.id == "settings_category_aggregations")
          return this.hasAggregatedOrders()

        return true
      }
    );

    if (activeChildren.length === 0) {
      return null;
    }
    const activeNavigationItemSyncData: NavigationItemObject = JSON.parse(
      JSON.stringify(this.navigationItemSettings)
    );
    activeNavigationItemSyncData.children = activeChildren;

    return activeNavigationItemSyncData;
  }

  setNavigationItemsWithRouteInfo(): void {
    this.navigationItemsWithRouteInfo = this.getNavigationItemsWithRouteInfo();
  }

  getNavigationItemsWithRouteInfo(): Array<NavigationItemObject> {
    return <NavigationItemObject[]> [
      this.navigationItemOverview,
      this.getActiveNavigationDailyInvoices(),
      this.getActiveNavigationItemSyncData(),
      this.getActiveNavigationItemSettings(),
      this.getActiveNavigationItemAuditLog(),
      // Uncomment to expose plugins navigation button
      // this.navigationItemPlugins
    ].filter((navigationItem) => navigationItem !== null);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    Navigation: Navigation;
  }
}
