import Route from '@ember/routing/route';
import { inject as service} from '@ember/service';
import Auth from '../services/auth';

export default class Signout extends Route {

  @service auth!: Auth;

  // This route isn't destination, so logout/redirect is immediately triggered.
  beforeModel() {
    this.auth.logout();
  }
}
