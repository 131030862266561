import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import CurrentUser from '../services/current-user';
import FlashService from '@square/glass-ui/addon/services/flash';
export default class Application extends Controller {

  @service currentUser!: CurrentUser;
  @service flash!: FlashService;

}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'application': Application;
  }
}
