import EmberRouter from '@ember/routing/router';
import config from 'bridge-dashboard/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.mount('@bridge/home-engine', {
    path: '/home',
  });

  this.route('account-activation-pending');
  this.route('login');
  this.route('not-found', { path: '/*path' });
  this.route('oauth2', function () {
    this.route('redirect');
  });
  this.route('signout');
  this.route('application-error');
  this.route('signup');

  this.route('authorized-route', function () {});
});
