import FlashService from '@square/glass-ui/addon/services/flash';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Auth from '../../services/auth';
import CurrentUser from '../../services/current-user';
import Transition from '@ember/routing/-private/transition';
import BridgeApiException from '../../types/bridge-api-exception';
/**
 * Successful OAuth2 SSO flows redirect to this route.
 * Another non-destination route.
 */
export default class Oauth2Redirect extends Route {
  @service auth!: Auth;
  // no type b/c Glass UI service w/o type
  @service currentUser!: CurrentUser;
  @service flash!: FlashService;

  afterModel() {
    this.transitionTo('@bridge/home-engine');
  }

  // see: https://guides.emberjs.com/release/routing/loading-and-error-substates/#toc_error-substates
  // handles all errors originated within authorized-route
  /* NOTE: value of rejected Promises or thrown objects must be Error object,
   * else error will not bubble as model to error substate route
   */
  @action
  error(error: BridgeApiException, transition: Transition) {
    if (error.response.status !== 401) {
      this.flash.globalWarning(
        `Please sign in to view the ${transition.to.localName} page.`
      );
      /* @ts-ignore the following method exists in an Ember Engine route */
      this.transitionToExternal('login');
      return false // TODO: Ensure this is correct implementation.
    } else {
      // Let the route above this handle the error.
      this.flash.globalError('error', {
        dismiss: () => {
          this.flash.clearGlobalMessage();
        },
      });
      return true;
    }
  }
}
