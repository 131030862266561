import Component from '@glimmer/component';

type AccountMenuTriggerArgs = {
  onClick: () => any
}

// this component triggers the account menu in the upper-right corner of the app
export default class AccountMenuTrigger extends Component<AccountMenuTriggerArgs> {
  
  defaultClickHandler = () => { console.error('No click handler passed to AccountMenuTrigger component') };

  clickHandler = this.args.onClick || this.defaultClickHandler;

}
