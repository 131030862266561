export const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_SECOND = 1000;
export const MILLISECONDS_IN_MINUTE =
  SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const MICROSECONDS_IN_SECOND = 1000000;
export const MICROSECONDS_IN_MILLISECOND = 1000;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;

export function convertMillisecondsToSeconds(milliseconds: number) {
  return milliseconds / MILLISECONDS_IN_SECOND;
}

export function convertMinutesToMilliseconds(minutes: number) {
  return minutes * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
}

export function convertMinutesToSeconds(minutes: number) {
  return minutes * SECONDS_IN_MINUTE;
}
