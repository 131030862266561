import Controller from '@ember/controller';

export default class Oauth2Redirect extends Controller {
  queryParams: Array<string> = ['token', 'error'];
  
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'oauth2/redirect': Oauth2Redirect;
  }
}