import Component from '@ember/component';
import { computed } from '@ember/object';

/**
## SVG Icon

`SvgIcon` is a block component that serves as a component wrapper for all SVGs in
dashboard-header. It takes care of accessibility attributes like aria labelling,
role, and title automatically. The viewBox attribute is automatically calculated
from the given width and height, but can be explicitly set by passing a viewBox
parameter. Since there are no classNames by default, CSS class may be passed as
an attribute as well.

```example
@title Vanilla SVG

@hbs
{{! svgs/my-button.hbs }}
{{#svg-icon width="16" height="16" title=(i18n-t "my_button")}}
  // SVG content goes here
  <path />
{{/svg-icon}}

{{! my-page.hbs }}
{{svgs/my-button}}
```

```example
@title SVG with custom viewBox

@hbs
{{! svgs/my-button.hbs }}
{{#svg-icon width="16"
            height="16"
            viewBox="0 0 24 24"
            title=(i18n-t "my_button")}}
  // SVG content goes here
  <path />
{{/svg-icon}}

{{! my-page.hbs }}
{{svgs/my-button}}
```

```example
@title SVG with class name

@hbs
{{! svgs/my-button.hbs }}
{{#svg-icon width="16"
            height="16"
            class="my-button"
            title=(i18n-t "my_button")}}
  // SVG content goes here
  <path />
{{/svg-icon}}

{{! my-page.hbs }}
{{svgs/my-button}}
```

```example
@title Reusable SVG with variable dimensions
@description If you need to re-use an SVG in different dimensions, set `width=width` and pass in a width value from the SVG component. Class names may be passed down from the top level component in the same manner.

@hbs
{{! svgs/my-button.hbs }}
{{#svg-icon width=width
            height=height
            class=class
            title=(i18n-t "my_button")}}
  // SVG content goes here
  <path />
{{/svg-icon}}

{{! my-page.hbs }}
{{svgs/my-button width="12" height="12" class="my-button--blue"}}
{{svgs/my-button width="24" height="24" class="my-button--red"}}
```

### Parameters
@param {String} width - The width attribute of the SVG
@param {String} height - The height attribute of the SVG
@param {String} title - Accessibility title property of the SVG
@param {String} viewBox - Optional - Custom viewBox property of the SVG
@param {String} class - Optional - Class names for the SVG
@param {String} iconCaddyColor - Optional - Specific class used to theme App Drawer icon background colors
@param {String} fill - Optional - Fill for the SVG icon
*/

export default Component.extend({
  tagName: 'svg',
  attributeBindings: [
    'activeViewBox:viewBox',
    'width',
    'height',
    'role',
    'fill',
  ],
  classNameBindings: ['iconCaddyColorClass'],

  // Passed in
  title: null,
  viewBox: null,
  width: null,
  height: null,
  iconCaddyColor: null,

  activeViewBox: computed('width', 'height', 'viewBox', function() {
    return this.viewBox || `0 0 ${this.width} ${this.height}`;
  }),

  role: 'img',

  iconCaddyColorClass: computed('iconCaddyColor', function() {
    return this.iconCaddyColor
      ? `app-page__section__item-list__item__icon--${this.iconCaddyColor}`
      : '';
  }),
});
