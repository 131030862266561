import Controller from '@ember/controller';

export default class Login extends Controller {
  queryParams = ['error_message'];

  error_message = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'login': Login;
  }
}
