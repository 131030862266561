import Service from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export const triggerClass = 'popover-menu__trigger';
export const focusableClass = 'popover-menu__item';
export const contentClass = 'popover-menu__content';

export const ACCOUNT_MENU_ELEMENT_ID = 'account-menu-popover';
export const TAKE_PAYMENT_ELEMENT_ID = 'take-payment-popover';
export const NOTIFICATION_DRAWER_ELEMENT_ID = 'notification-drawer-popover';
export const SETUP_GUIDE_ELEMENT_ID = 'setup-guide-popover';
export const HELP_MENU_ELEMENT_ID = 'help-menu-popover';


export default class PopoverManagerExtension extends Service {
  @tracked currentPopoverID: string | null = null;

  /**
   * Whether to visually show the veil when the popover is open. By default the veil is not visual.
   *
   * Note that when the veil is not visual, it still blocks interaction with the page. "isBlocking"
   * is the parameter that avoids blocking the page at all
   */
  get useVisualVeil(): boolean {
    return this.currentPopoverID === SETUP_GUIDE_ELEMENT_ID;
  }

  /**
   * Whether to have a veil at all. If false, no veil is included and interaction with the rest
   * of the page is allowed.
   */
  get isBlocking() {
    if (this.currentPopoverID) {
      return true;
    }
    return false;
  }

  constructor() {
    super();

    window.addEventListener('keyup', this.keyupHandler);
    window.addEventListener('click', this.clickAndTapHandler, true);
    window.addEventListener('touchend', this.clickAndTapHandler, true);
  }

  keyupHandler(event: KeyboardEvent) {
    if (!this.isBlocking) {
      return;
    }

    if (event.key === 'Escape') {
      // restore focus to trigger element

      // @ts-ignore
      scheduleOnce('afterRender', this.restoreFocus, 'optional');

      this.close();
    }
  }

  restoreFocus() {
    const el = document.querySelector(`#${this.currentPopoverID} .${triggerClass}`);
    if (el && el instanceof HTMLElement) {
      el.focus()
    }
  }

  clickAndTapHandler(event: MouseEvent) {
    if (!this.isBlocking) {
      return;
    }

    const target = event.target as Element;
    const activePopoverSelector = `#${this.currentPopoverID}`;
    const wasClickOutsidePopover =
      target!.closest(activePopoverSelector) === null;

    if (wasClickOutsidePopover) {
      this.close();
    }
  }

  willDestroy() {
    window.removeEventListener('keyup', this.keyupHandler);
    window.removeEventListener('click', this.clickAndTapHandler, true);
    window.removeEventListener('touchend', this.clickAndTapHandler, true);
  };

  open(elementId: string) {
    this.currentPopoverID = elementId;
  };

  close() {
    this.currentPopoverID = null;
  };
};
