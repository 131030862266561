import Integration from '@bridge/home-engine/addon/models/services/integration/integration';
import SyncDefinition from '@bridge/home-engine/addon/models/services/integration/sync-definition';
import {
  LocationMapping,
  Source,
} from '@bridge/home-engine/addon/models/services/source/source';
import {
  SyncTypeStatus
  // @ts-ignore module exists in ember namespace but ts wants literal path
} from '@bridge/home-engine/models/services/sync-type-status/sync-type-status';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import AbridgedIntegration from '../types/abridged-integration';
import BridgeApiException from '../types/bridge-api-exception';
import { IntegrationState } from '../types/integration-state_enum';

import Data from './data';
import FetchHandler from './fetch-handler';
import Navigation from './navigation';
import getNameFromIntegrationSource from '../utils/integration-source-utils';

interface User {
  id: string;
  email: string;
  name: string;
  accounts: Array<BridgeAccountIntegrations>;
  terms_accepted: boolean;
}

interface BridgeAccountIntegrations {
  id: string;
  integrations: Array<AbridgedIntegration>;
}

interface FetchUserResponse {
  user: User;
}

export { User, BridgeAccountIntegrations, FetchUserResponse };

/**
 * Service responsible for tracking current user state.
 * Mostly used for tracking the integration which the current user is viewing,
 * and the associated account, so the appropriate API requests can be made.
 */
export default class CurrentUser extends Service {
  @service declare data: Data;
  @service declare fetchHandler: FetchHandler;
  @service declare navigation: Navigation;

  @tracked declare user: User | null;
  @tracked declare currentAccount: BridgeAccountIntegrations | null;
  @tracked declare currentIntegrationId: string;
  @tracked currentIntegration: Integration | null = {} as Integration;

  syncTypeStatuses: SyncTypeStatus[] = [];

  /* @ts-ignore Ember's ENV type doesn't include baseApiUrl property, curiously */
  currentUserEndpoint = '/api/v1/users/me';
  expired = false;

  async updateIntegration(integration: Integration): Promise<void> {
    this.currentIntegration = integration;
    this.syncTypeStatuses = await this.data.getSyncTypeStatuses(this.currentIntegrationId);
    this.navigation.setNavigationItemsWithRouteInfo();
  }

  async loadUser(): Promise<User> {
    return await this.fetchHandler
    .doGetAndHandleResponse<FetchUserResponse>(this.currentUserEndpoint)
      .then(
        (fetchUserResponse: FetchUserResponse) => {
          if (fetchUserResponse.user) {

            this.user = fetchUserResponse.user!;
            this.expired = false;
            this.currentAccount = this.user.accounts[0];

            // If the user has an integration
            if (this.hasIntegration()) {
              const selectedIntegrationId = localStorage.getItem(
                'selectedIntegrationId'
              );

              /* Check if a valid integration id has been saved in localstorage,
               * if so, select that integration, otherwise pick first one
               */
              if (
                selectedIntegrationId &&
                this.currentAccount.integrations.find(
                  (integration) => integration.id === selectedIntegrationId
                )
              ) {
                this.currentIntegrationId = selectedIntegrationId;
              } else {
                this.currentIntegrationId = this.currentAccount.integrations[0].id;
              }
            }

          }
          return fetchUserResponse?.user!
        }
        ,
        (rejectedPromise: BridgeApiException) => {
          throw rejectedPromise;
        }
      )
  }

  async loadIntegration(): Promise<void> {
    if (this.currentAccount?.integrations
      &&this.currentAccount.integrations.length > 0
    ) {
      const syncTypeStatusesPromise = this.data.getSyncTypeStatuses(this.currentIntegrationId);
      const integrationPromise: Promise<Integration> = this.data.getIntegration(
        this.currentIntegrationId
      );
      return await Promise.all([integrationPromise, syncTypeStatusesPromise])
      .then(
        (values) => {
          this.currentIntegration = values[0];
          this.syncTypeStatuses = values[1];
          this.navigation.setNavigationItemsWithRouteInfo();
        }
      );
    } else {
      return Promise.resolve();
    }
  }

  changeCurrentIntegrationId(integrationId: string): void {
    if (
      this.currentAccount &&
      this.currentAccount.integrations.find(
        (integration) => integration.id === integrationId
      )
    ) {
      this.currentIntegrationId = integrationId;
    }
  }

  async acceptTerms(): Promise<User> {
    return await this.fetchHandler
      .doPostAndHandleResponse<FetchUserResponse>(
        `${this.currentUserEndpoint}/accept-terms`,
        {}
      )
      .then((json) => {
        this.user = json.user;
        return json.user;
      });
  }

  hasIntegration(): boolean {
    return (
      this.currentAccount!.integrations &&
      this.currentAccount!.integrations.length > 0
    );
  }

  isIntegrationConfigured(): boolean {
    return Boolean(
      this.currentExternalSource?.cloud_elements_instance?.instance_variables
        ?.object_version
      || this.currentExternalSource?.external_source?.configuration
    );
  }

  isLocationConfigured(locationId: string): boolean {
    const locationSyncDefinitions = this.currentIntegration!.sync_definitions!.filter(
      (definition) => definition.location_id === locationId
    );
    return (
      Boolean(this.getLocationMapping(locationId)) &&
      locationSyncDefinitions.length > 0
    );
  }

  isAnyLocationConfigured(): boolean {
    const locations = this.currentExternalSource
      ?.cloud_elements_instance
      ?.instance_variables
      ?.location_mappings?.length
      || this.currentExternalSource?.external_source?.configuration
        &&
        this.currentExternalSource
        ?.external_source
        ?.configuration
        ?.location_mappings?.length
      || 0;
    return locations > 0;
  }

  isAdvancedTaxesEnabled(): boolean {
    return Boolean(
      this.currentExternalSource?.cloud_elements_instance?.instance_variables?.is_advanced_taxes_enabled
    );
  }

  isCompletelyConfigured(): boolean {
    return (
      this.hasIntegration() &&
      this.isIntegrationConfigured() &&
      (this.isAnyLocationConfigured() ||
        this.currentIntegration!.sync_definitions!.length > 0)
    );
  }

  clearCurrentIntegration(): void {
    this.currentIntegration = {} as Integration;
  }

  clear(): void {
    this.user = null;
    this.expired = true;
    this.currentAccount = null;
    this.currentIntegration = null;
  }

  // Getters
  get activeSyncTypes(): Array<SyncTypeStatus> {
    if (this.syncTypeStatuses) {
      return this.syncTypeStatuses;
    }
    return [];
  }

  get currentIntegrationName(): string {
    return this?.currentIntegration?.name
      || this.currentExternalSource && getNameFromIntegrationSource(this.currentExternalSource)
      || '';
  }

  get currentSquareSource(): Source | null {
    if (this.currentIntegration?.sources === undefined) {
      return null;
    }
    return this.currentIntegration?.sources.filter(
      (source) => source.gateway === 'SQUARE'
    )[0];
  }

  get currentExternalSource(): Source | null {
    if (this.currentIntegration!.sources === undefined) {
      return null;
    }
    return this.currentIntegration!.sources.filter(
      (source) => source.gateway !== 'SQUARE'
    )[0];
  }

  get locationIds(): string[] {
    if (this.currentIntegration?.sync_definitions === undefined) {
      return [];
    }
    const syncDefinitionLocationIds: string[] = this.currentIntegration.sync_definitions
      .filter((definition: SyncDefinition) => Boolean(definition))
      .map((definition: SyncDefinition) => definition.location_id!);

    return [...new Set(syncDefinitionLocationIds)];
  }

  get currentIntegrationState(): IntegrationState {
    if (this.isCompletelyConfigured()) {
      return IntegrationState.COMPLETE;
    }
    return IntegrationState.SETUP;
  }

  // Private methods
  private getLocationMapping(locationId: string): LocationMapping {
    if (this.isExternalSource(this.currentExternalSource)) {
      return this.currentExternalSource!.external_source!.configuration!.location_mappings!.find(
        (locationMapping) => locationMapping.square_location_id === locationId
        )!;
      } else {
        return this.currentExternalSource!.cloud_elements_instance!.instance_variables!.location_mappings!.find(
          (locationMapping) => locationMapping.square_location_id === locationId
        )!;
    }
  }

  private isExternalSource(source: Source | null): boolean {
    // Workaround by comparing string since there's some issue importing enum in TS - azhengxi
    // TODO: fix this
    return !!source && !!source.external_source && source.gateway !== 'CLOUD_ELEMENTS';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'current-user': CurrentUser;
  }
}
